#hi-health-insurance {
    padding-top: 80px;
    min-height: 71vh;
    background-image: url('../../../public/assets/health-insurance.webp');
}

#hi-health-insurance h3 {
    color: white;
    font-size: 40px;
    font-weight: normal;
    text-shadow: 2px 2px #333;
}

#hi-health-insurance .title-page {
    max-width: 580px;
    text-align: left;
    margin: 0;
    font-size: 50px;
    text-shadow: 2px 2px #333;
    margin-bottom: 80px;
    font-weight: normal;
}

#hi-health-insurance .card-list {
    padding: 40px;
    background-color: white;
}

#hi-health-insurance .card-list ul {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0px;
    width: 278px;
}

#hi-health-insurance .card-list ul li {
    list-style: none;
    margin-left: -30px;
}

#hi-health-insurance .card-list ul li p,
#hi-health-insurance .card-list ul li span {
    color: #3b4850;
    display: inline-block;
    vertical-align: top;
}

#hi-health-insurance .card-list ul li p {
    width: 240px;
    margin-left: 5px;
}