#h-board-directors {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #1a1a1a;
}

#h-board-directors .title-page {
    margin-bottom: 80px;
}

#h-board-directors .row img {
    border-radius: 50%;
}

#h-board-directors h3 {
    color: #d79a35;
    margin-top: 30px;
}

#h-board-directors label {
    color: white;
}