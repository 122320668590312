#a-top {
    padding-top: 20px;
    height: 950px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -130px;
    background-image: url('../../../public/assets/dominican_cinema.webp');
}

#a-history {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: white;
}

#a-history .title-page {
    text-align: left; 
    margin-bottom: 30px;
}

#a-history p {
    color: #3b4850;
    max-width: 400px;
}

#a-history .row {
    margin-top: 40px;
}

#a-why-member {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: white;
}

#a-why-member .title-page {
    text-align: left;
    font-weight: normal;
}

#a-why-member .decription {
    max-width: 650px;
    margin-top: 10px;
    color: #3b4850;
}

#a-why-member ul {
    margin-left: -30px;
}

#a-why-member ul li {
    list-style: none;
}

#a-why-member ul li span, #a-why-member ul li p {
    display: inline-block;
    vertical-align: middle;
}

#a-why-member ul li span {
    color: #9b9fa9;
    font-size: 80px;
    margin-right: 20px;
}

#a-why-member ul li p {
    color: #3b4850;
    font-size: 20px;
    max-width: 400px;
}