#header-page {
    padding: 10px;
    background-color: #d3d3d3;
}

#header-page .options {
    margin-top: 15px;
}

#header-page span {
    display: inline-block;
    vertical-align: middle;
}

#header-page span.button {
    padding: 3px;
    cursor: pointer;
    color: white;
    margin-right: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    background-color: #d79a35;
}

#header-page span.nav {
    cursor: pointer;
    display: inline-block;
    color: #d69a35;
    font-size: 28px;
    margin-left: 20px;
}

#header-page .menu {
    padding: 10px;
    display: none;
    position: absolute;
    background-color: white;
    width: 300px;
    right: 390px;
    margin-top: -10px;
    border-radius: 10px;
    border: solid 1px gainsboro;
}

#header-page span.nav:hover > .menu {
    display: block;
}

#header-page .menu li {
    list-style: none;
    margin-left: -30px;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: solid 2px #d79a35;
}

#header-page .menu li:last-child, #header-page .menu li:nth-child(2), #header-page .menu li:nth-child(3) { 
    padding: 0px;
    border-bottom: none;
}

#header-page .menu li:nth-child(3) a, #header-page .menu li:nth-child(4) a {
    font-weight: normal;
}

#header-page .menu li a{ 
    color: #333333;
    font-size: 17px;
    font-weight: bold;
    text-decoration: none;
}

#header-page .menu li a:hover{ 
    color: #d69a35;
}

#header-page .menu li:nth-child(2) a:hover {
    color: #333333;
    cursor: text;
}

#header-page .bar-auth {
    margin-left: 5px;
    margin-right: 5px;
    color: #aeaeae
}

#header-page .option-auth {
    cursor: pointer;
    text-decoration: none;
}

#header-page .option-auth:hover {
    color: #d69a35;
}

#header-page .bar-auth {
    margin-left: 5px;
    margin-right: 5px;
}

#social-media-header {
    margin-top: 15px;
}

#social-media-header li{
    display: inline-block;
    list-style: none;
    color: white;
    width: 40px;
    font-size: 25px;
    margin-right: 40px;
    border-radius: 5px;
    text-align: center;
    margin-left: -15px;
    background-color: #d69a35;
}

#social-media-header li:last-childd {
    margin-right: 0px;
}

#login .modal-body button {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    border-color: #d69a35;;
    background-color: #d69a35;
}

#login .modal-body {
    text-align: center;
}

#login .modal-body label {
    width: 100%;
    text-align: left;
}

#login .modal-title {
    width: 100%;
    text-align: center;
}