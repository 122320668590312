#h-contact {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #1a1a1a;
}

#h-contact p {
    font-size: 18px;
    max-width: 300px;
    color: white;
}

#h-contact .title-page {
    margin-bottom: 80px;
}

#h-contact .social-media {
    margin-left: -15px;
    margin-top: 80px;
}

#h-contact .social-media li {
    display: inline-block;
    list-style: none;
    color: #000;
    width: 40px;
    font-size: 25px;
    margin-right: 40px;
    border-radius: 5px;
    text-align: center;
    margin-left: -15px;
    background-color: #d69a35;
}

#h-contact .container-map {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

#h-contact .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}