#vt-top {
    padding-top: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -130px;
    background-image: url('../../../public/assets/concert.webp');
    background-color: #000;
}

#vt-top .nav-bar a,
#vt-top .nav-bar span {
    text-shadow: 2px 2px #333;
}

#vt-top .container {
    height: 800px;
    background-size: 4%;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: bottom;
    background-image: url('../../../public/assets/figure.png');
}

#vt-top .container .title-page {
    font-size: 60px;
    margin-top: 100px;
    text-shadow: 2px 2px #333;
}

#vt-top .container .decription {
    color: white;
    text-align: center;
    font-size: 23px;
    max-width: 800px;
    font-size: 25px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    text-shadow: 2px 2px #333;
}

#vt-top span.button {
    padding: 5px;
    cursor: pointer;
    color: white;
    font-size: 18px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 30px;
    background-color: #d79a35;
}

#vt-nominations {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #1a1a1a;
}

#vt-nominations .container .card-nomination {
    padding: 20px;
    margin-bottom: 30px;
    background-color: white;
}

#vt-nominations .container .card-nomination .title-page {
    font-size: 30px;
}

#vt-nominations .container .card-nomination ul {
    margin-top: 40px;
}

#vt-nominations .container .card-nomination ul li {
    list-style: none;
    margin-left: -30px;
}

#vt-nominations .container .card-nomination ul li p {
    width: 83%;
}

#vt-nominations .container .card-nomination ul li p strong,
#vt-nominations .container .card-nomination ul li p label {
    display: block;
    font-size: 18px;
}

#vt-nominations .container .card-nomination ul li p,
#vt-nominations .container .card-nomination ul li button {
    display: inline-block;
    vertical-align: middle;
}

#vt-nominations .container .card-nomination button {
    cursor: pointer;
    color: white;
    width: 60px;
    padding: 5px;
    font-size: 15px;
    border-radius: 20px;
    border-color: #d79a35;
    background-color: #d79a35;
}

#vt-nominations .container .card-nomination button:hover {
    border-color: #b07b24;
    background-color: #b07b24;
}

#vt-nominations .container .closed-voting {
    padding: 30px;
    font-size: 20px;
    text-align: center;
    max-width: 650px;
    margin: 0 auto;
    margin-top: 60px;
    background-color: white;
}

#vt-nominations .container .closed-voting p {
    margin: 0px;
    font-size: 25px;
    color: #333;
}