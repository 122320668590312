#mv-top {
    height: 300px;
    padding-top: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -680px;
    background-image: url('../../../public/assets/tape.webp');
    background-color: #090909;
}

#mv-top .nav-bar a,
#mv-top .nav-bar span {
    text-shadow: 2px 2px #333;
}

#mv-top .container .title-page {
    font-size: 60px;
    max-width: 700px;
    margin-top: 100px;
    text-shadow: 2px 2px #333;
}

#mv-top .container .decription {
    color: white;
    font-size: 23px;
    max-width: 350px;
    font-size: 25px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    text-shadow: 2px 2px #333;
}

#mv-top span.button {
    padding: 5px;
    cursor: pointer;
    color: white;
    font-size: 18px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 30px;
    background-color: #d79a35;
}

#mv-movie {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #1a1a1a;
}

#mv-movie .title-page {
    margin-bottom: 40px;
}

#mv-movie .row {
    margin-bottom: 80px;
}

#mv-movie .col {
    text-align: right;
}

#mv-movie .col:last-child {
    text-align: left;
}

#mv-movie .col:last-child .title-page {
    text-align: left;
}

#mv-movie iframe, #mv-movie .iframe {
    width: 900px;
    height: 500px;
    margin: 0 auto;
}

#mv-movie p {
    color: white;
}

#mv-movie strong {
    margin-right: 10px;
}