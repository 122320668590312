#h-contact {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #1a1a1a;
}

#h-contact p {
    font-size: 18px;
    max-width: 300px;
    color: white;
}

#h-contact .title-page {
    margin-bottom: 80px;
}

#h-contact .social-media {
    margin-left: -15px;
    margin-top: 80px;
}

#h-contact .social-media li {
    display: inline-block;
    list-style: none;
    color: #000;
    width: 40px;
    font-size: 25px;
    margin-right: 40px;
    border-radius: 5px;
    text-align: center;
    margin-left: -15px;
    background-color: #d69a35;
}

.nav-bar {
    margin-left: -30px;
}

.nav-bar li {
    list-style: none;
}

.nav-bar a, .nav-bar span {
    color: white;
    font-size: 18px;
    text-decoration: none;
}

.nav-bar span {
    padding-left: 10px;
    padding-right: 10px;
}