#footer-page {
    width: 100%;
    height: 200px;
    padding-top: 120px;
    background-size: contain;
    background-repeat: repeat-x;
    background-color: #1a1a1a;
    background-position-y: 13px;
    background-image: url('../../../../public/assets/city.png');
}

#footer-page p {
    color: white;
}

#footer-page .copyright {
    margin-top: 20px;
}